import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ApiDataService } from '../services/api-data.service';
import { DataService } from '../services/data.service';
import { ImageService } from '../services/image.service';
import { Location } from '@angular/common';
declare var Stripe;

@Component({
  selector: 'app-select-deliver',
  templateUrl: './select-deliver.component.html',
  styleUrls: ['./select-deliver.component.scss'],
})
export class SelectDeliverComponent implements OnInit {

  HEADING: string = "Select delivery";
  DELIVERY: boolean = false;
  SUB_TOTAL: number = 0;
  stripe;
  card: any;
  EMAIL: any;
  PAYMENT_MODEL_OPEN: boolean = false;
  STRIPE_FLAG: boolean;
  constructor(
    private router: Router,
    private apiData: ApiDataService,
    private dataService: DataService,
    public imageService: ImageService,
    public activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private location: Location,
  ) { }

  ngOnInit() { }
  async ionViewWillEnter() {
    this.SUB_TOTAL = Number(this.activatedRoute.snapshot.paramMap.get('SUB_TOTAL'));
    let owner_data = await this.dataService._getOwnerData();

    if (owner_data) {
      this.stripe = Stripe(owner_data.stripe_publishable_key);
      this.STRIPE_FLAG = owner_data.stripe;
    }
    await this.auth.getUser().subscribe(
      async (response: any) => {
        // Get auth data
        this.EMAIL = response.email;
      },
      (error: any) => {
      }
    );
    await this._setupStripe();
  }
  async confirm() {
    if (this.STRIPE_FLAG) {
      this.PAYMENT_MODEL_OPEN = true;
    }
    else {
      await this.apiData.presentAlertWithHeader("Unavailable", "Online purchase unavailable");
    }
  }
  async _setupStripe() {

    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
          class: 'vijay'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style: style, hidePostalCode: true });
    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();

      this.stripe.createToken(this.card).then(result => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          this._createPayment(result.token.id);
        }
      });
    });
  }


  async _createPayment(token: any) {

    let amount = this.SUB_TOTAL;
    let formData = new FormData();
    formData.append('email', this.EMAIL);
    formData.append('token', token);
    formData.append('amount', amount.toString());
    formData.append('transactionType', String(3));
    formData.append('description', "Online Store Payment");
    await this.apiData.presentLoading();
    await (await this.apiData._createPayment(formData)).subscribe(
      async (response: any) => {

        if (response.id) {
          // this.RECIPT_URL = response.receiptUrl;
          await this.apiData.dismiss();
          await this.apiData.presentAlertWithHeader("Payment successful", "Please check your email for further details");
        } else {
          // alert(response.details);

        }
      },
      async (error: any) => {
        // alert('server error');
        await this.apiData.dismiss();
        await this.apiData.presentAlertWithHeader("Payment Failed", "Something Went Wrong. Please try later.");
      }
    );
  }
  navigation() {
    // this.router.navigate(['/cart-detail']);
    this.location.back();
  }
}
